import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { StyledAppleButton } from './styles';
import { DEFAULT_SOCIAL_EXTRA_PAYLOAD } from '@/constants/authentication';
import { K_ANALYTICS_EVENTS, LOG_EVENTS } from '@/constants/events';
import { useSelector } from '@/redux';
import { type IUserLogin } from '@/types/authentication';
import { type IAppleSignInAuthorizationResponse } from '@/types/constants/global_config';
import { getParsedJwt } from '@/utils/auth';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

interface Props {
  onLogin: (values?: IUserLogin) => void;
  onLoginFailure: (error?: any) => void;
}
export function AppleLoginButton({ onLogin, onLoginFailure }: Props) {
  const appleServiceId = useSelector(
    (state) => state.app.cmsConfig.webConfig.integrations.apple_service_id,
  );
  const route = useRouter();

  const initApple = useCallback(() => {
    window.AppleID.auth.init({
      clientId: appleServiceId, // Service ID in Apple dashboard
      scope: 'name email',
      redirectURI: window.location.origin, // Must be the same URI registered with your service ID
      usePopup: true, // Important if we want to capture the data Apple sends on the client side
    });
  }, [appleServiceId]);

  const handleAppleLogin = useCallback(
    (event: CustomEvent<IAppleSignInAuthorizationResponse>) => {
      const secret = encodeURIComponent(event.detail.authorization.code);
      const jwt = getParsedJwt(event.detail.authorization.id_token);
      const identifier = encodeURIComponent(jwt ? jwt.sub : '');

      const socialPayload = {
        // Required only for Apple
        redirect_uri: window.location.origin,
        // Base social data
        identifier,
        provider: 'apple',
        secret,
      };

      let optionalUserDetails: IUserLogin = {};
      // Apple only includes a user object on first log-in
      if (event.detail?.user) {
        optionalUserDetails = {
          // Include first and last name if they're returned
          first_name: event.detail?.user?.name?.firstName,
          last_name: event.detail?.user?.name?.lastName,
        };
      }

      const payload = {
        ...socialPayload,
        ...optionalUserDetails,
        ...DEFAULT_SOCIAL_EXTRA_PAYLOAD,
      };

      onLogin(payload);
    },
    [onLogin],
  );

  const handleAppleFailure = useCallback(
    (error: CustomEvent) => {
      if (error?.detail?.error !== 'popup_closed_by_user') {
        onLoginFailure(error);
      }
    },
    [onLoginFailure],
  );

  useEffect(() => {
    if (typeof window.AppleID !== 'undefined') {
      initApple();
    }

    document.addEventListener('AppleIDSignInOnSuccess', handleAppleLogin as EventListener);
    document.addEventListener('AppleIDSignInOnFailure', handleAppleFailure as EventListener);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleAppleLogin as EventListener);
      document.removeEventListener('AppleIDSignInOnFailure', handleAppleFailure as EventListener);
    };
  }, [appleServiceId, handleAppleFailure, handleAppleLogin, initApple]);

  return (
    <StyledAppleButton
      id="appleid-signin"
      data-mode="center-align"
      data-type="sign-in"
      data-color="black"
      data-border="false"
      data-height="40"
      data-width="100%"
      onClick={() => {
        // KA event
        fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
          name: LOG_EVENTS.APPLE_LOGIN_CLICK,
          details: route.route,
        });
      }}
    />
  );
}
