import { useForm } from 'react-hook-form';
import {
  StyledFormField,
  StyledGenericForm,
  StyledGenericFieldset,
  StyledFormGroup,
  FormInput,
} from '@/components/uielements/form-fields/styles';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { DEFAULT_PASSWORD_LOGIN_EXTRA_PAYLOAD } from '@/constants/authentication';
import { type IUserLogin } from '@/types/authentication';
import StringAccessor from '@/components/cmsConfig/stringAccessor';

interface Props {
  loading: boolean;
  onLogin: (values?: IUserLogin, referrer?: string | boolean | null) => void;
  referrer?: string | boolean | null;
}

const LoginForm: React.FC<Props> = ({ loading, onLogin }) => {
  const { handleSubmit, register } = useForm();
  const extraPayload = DEFAULT_PASSWORD_LOGIN_EXTRA_PAYLOAD;
  const accessor = !loading ? 'login.cta' : 'login.cta_loading';

  const onSubmit = (data: IUserLogin | any) => {
    onLogin(
      Object.assign(
        { identifier: data.identifier, secret: data['current-password'] },
        extraPayload,
      ),
    );
  };

  return (
    <StyledGenericForm onSubmit={handleSubmit(onSubmit)}>
      <StyledGenericFieldset>
        <legend>Login form</legend>
        <StyledFormGroup>
          {/* Login Inputs */}
          <StyledFormField key={`login-form-field-identifier`}>
            <FormInput
              key="identifier"
              type="email"
              placeholder="Email"
              autoComplete="email"
              {...register('identifier')}
              autoFocus={false}
            />
          </StyledFormField>
          <StyledFormField key={`login-form-field-password`}>
            <FormInput
              key="secret"
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              {...register('current-password')}
              autoFocus={false}
            />
          </StyledFormField>
        </StyledFormGroup>
      </StyledGenericFieldset>

      {/* Submit Button */}
      <StyledPrimaryButton
        data-testid="signin-button"
        $submitting={loading}
        type="submit"
        style={{ width: '100%' }}
        $buttonTheme="secondary"
      >
        <StringAccessor accessor={accessor} />
      </StyledPrimaryButton>
    </StyledGenericForm>
  );
};

export default LoginForm;
