import styled from 'styled-components';
import { LAYOUT } from '@/constants/styles';

export const OrBlock = styled.div`
  hr {
    margin: 0 !important;
  }
`;

export const Circle = styled.div`
  position: relative;
  top: -${LAYOUT.GUTTER}px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
  width: 40px;
  height: 40px;
  color: #fff;
  background: #ccc;
  margin: 0 auto;
`;

export const SocialBox = styled.div`
  margin-top: ${LAYOUT.GUTTER}px;
  padding: ${LAYOUT.GUTTER}px 0 0;
  text-align: center;
`;

export const SocialButton = styled.button`
  background: none;
  border: none;
`;

export const StyledSocialButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 50%;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 375px; // Max-width set by Apple
  }
`;
