import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import { SocialButtons } from '../socialButtons/index';
import LoginForm from './form';
import ReCaptcha, { type ReCaptchaRef } from '@/components/uielements/recaptcha';
import { CAPTCHA_CONFIG } from '@/constants/authentication';
import { type RootState } from '@/types/app';
import { type IUserLogin } from '@/types/authentication';
import { isAndroidShell } from '@/utils/android';
import { parseReferrer } from '@/utils/global';
import authActions from '@/redux/auth/actions';

const LoginComponent = () => {
  const dispatch = useDispatch();
  const [recaptchaRef, setRecaptchaRef] = useState<ReCaptchaRef | undefined>(undefined);
  const [formValues, setFormValues] = useState<IUserLogin | undefined>();
  const loading = useSelector((state: RootState) => state.app.auth.loading);

  const loginFactory = (formValues: IUserLogin | undefined, captchaResponse?: string | null) => {
    // Respect the referrer ONLY if we're on the login page
    const referrer = parseReferrer(Router);
    dispatch(
      authActions.login(
        Object.assign({}, formValues, {
          [CAPTCHA_CONFIG.FIELD_NAME]: captchaResponse,
        }),
        referrer,
      ),
    );
    // If we're using a captcha, reset the captcha
    if (captchaResponse) {
      /** @TODO ensure that the ref is defined. */
      recaptchaRef?.current?.reset();
    }
    setFormValues(formValues);
  };

  const checkReCaptcha = async (formValues: IUserLogin | undefined) => {
    if (recaptchaRef?.current) {
      const captchaResponse = await recaptchaRef.current.executeAsync();
      loginFactory(formValues, captchaResponse);
    } else {
      loginFactory(formValues);
    }
  };

  useEffect(() => {
    if (formValues) {
      checkReCaptcha(formValues);
    }
  }, [formValues]);

  return (
    <>
      {/** @TODO ensure that UserInfo is defined from the callback. */}
      <LoginForm onLogin={checkReCaptcha} loading={loading} />
      {!isAndroidShell() && <SocialButtons onLogin={loginFactory} />}
      {/* Conditional ReCaptcha */}
      <ReCaptcha
        onRef={(recaptchaRef) => setRecaptchaRef(recaptchaRef)}
        forwardRef={recaptchaRef}
        action={CAPTCHA_CONFIG.API_ACTIONS.LOGIN}
      />
    </>
  );
};

export default LoginComponent;
