import { type FC, useEffect, useRef } from 'react';
import ReactReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { CAPTCHA_ACTIONS } from '@/constants/authentication';
import type { RootState } from '@/types/app';
import * as ErrorReporter from '@/utils/errorReporter';

export type ReCaptchaRef = React.RefObject<ReactReCAPTCHA>;

interface Props extends ReduxProps {
  onRef: (ref: ReCaptchaRef) => void;
  action: CAPTCHA_ACTIONS;
  forwardRef: ReCaptchaRef | undefined;
}

const ReCaptcha: FC<Props> = ({ onRef, organization, action }) => {
  const reCaptchaRef = useRef<ReactReCAPTCHA>(null);

  useEffect(() => {
    onRef(reCaptchaRef);
  }, [onRef]);

  const sitekey = organization?.external_auth?.captcha?.site_key;
  const actions = organization?.external_auth?.captcha?.actions || [];

  if (!sitekey || !actions.includes(action)) {
    return null;
  }

  return (
    <ReactReCAPTCHA
      ref={reCaptchaRef}
      onError={ErrorReporter.captureException}
      sitekey={sitekey}
      badge="bottomright"
      size="invisible"
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  organization: state.app.organization,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(ReCaptcha);
