import useSocialLogin from 'react-social-login';
import { SocialButton } from './styles';

interface Props {
  children: React.ReactNode;
  triggerLogin: () => void;
}

const Button = ({ children, triggerLogin }: Props) => {
  return <SocialButton onClick={triggerLogin}>{children}</SocialButton>;
};

export default useSocialLogin(Button);
