import { type WebConfig } from '@koala/sdk';
import Link from 'next/link';
import Router, { withRouter, type NextRouter } from 'next/router';
import { compose } from 'redux';
import { useEffect } from 'react';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import {
  StyledAuthPageContent,
  StyledAuthPageLayout,
} from '@/components/app/authForms/layout/styles';
import LoginForm from '@/components/app/authForms/loginForm/index';
import Layout from '@/components/app/layout/index';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { StyledHr } from '@/components/uielements/hr';
import { ROUTES } from '@/constants/routes';
import { userLoggedIn } from '@/utils/auth';
import { deriveReferrer } from '@/utils/global';
import { safelyGetConfig } from '@/utils/stringHelpers';

interface LoginProps {
  router: NextRouter;
  onLogin: (params?: object) => void;
  authLoading: boolean;
  webConfig: WebConfig;
}

const Login = ({ router, webConfig }: LoginProps) => {
  useEffect(() => {
    if (userLoggedIn()) {
      void Router.push(ROUTES.HOMEPAGE);
    }
  }, []);

  const signupReferrer = deriveReferrer(router);

  return (
    <Layout
      $backgroundOverride={true}
      disabled={!!safelyGetConfig(webConfig, 'accounts.disable_accounts')}
    >
      <StyledAuthPageLayout>
        <StyledAuthPageContent>
          <StringAccessor tag="h1" accessor="login.header" html={true} />
          <StringAccessor tag="h3" accessor="login.subheader" html={true} />
          <StyledHr $background="transparent" />
          <LoginForm />
          <p>
            <Link
              href={{ pathname: ROUTES.FORGOT_PASSWORD }}
              passHref={true}
              onClick={() => genericEventHandler(GlobalEvents.AUTH__CLICK_FORGOT_PASSWORD)}
            >
              <StringAccessor accessor="forgot_password.prompt" />
            </Link>
          </p>
          <StyledHr />
          <p>
            <StringAccessor accessor="signup.prompt" html={true} />
            &nbsp;
            <Link
              href={`${ROUTES.SIGN_UP}?referrer=${signupReferrer}`}
              onClick={() => genericEventHandler(GlobalEvents.AUTH__CLICK_SIGN_UP)}
            >
              <StringAccessor accessor="signup.cta" />
            </Link>
          </p>
        </StyledAuthPageContent>
      </StyledAuthPageLayout>
    </Layout>
  );
};

export default compose(withRouter)(Login);
